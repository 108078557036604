export const SIGN_UP = '/signup';
export const SIGN_IN = '/signin';
export const LANDING = '/';
export const HOME = '/home';
export const PODS = '/pods';
export const ACCOUNT = '/account';
export const ADMIN = '/admin';
export const USER = '/user';
export const PASSWORD_FORGET = '/pw-forget';
export const ADMIN_DETAILS = '/admin/:id';
export const ADMIN_CREATE = '/admin/create';
export const USER_DETAILS = '/user/:id';
